import axios from "axios";
import apiConfig from "@/configs/apiConfig";
import { getAuth } from "firebase/auth";

const axiosClient = axios.create({
  baseURL: apiConfig.API_BASE_URL,
  timeout: 30000, // Timeout
});

// Função para obter o token atual do usuário logado
const getFirebaseToken = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser) {
    return await currentUser.getIdToken();
  }
  return null;
};

// Interceptador para adicionar o Bearer Token
axiosClient.interceptors.request.use(async (config) => {
  const token = await getFirebaseToken();
  if (token) {
    console.log("Token", token);
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Interceptador de resposta para lidar com erros globais
axiosClient.interceptors.response.use((response) => response, (error) => {
  return Promise.reject(error);
});

export default axiosClient;
