import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth"; // A persistência já foi configurada

const firebaseConfig = {
    apiKey: "AIzaSyAXEnkkG3yrsoD8411QaQh0kDByoOEo4BE",
    authDomain: "dataloyaltyio-front.firebaseapp.com",
    projectId: "dataloyaltyio-front",
    storageBucket: "dataloyaltyio-front.firebasestorage.app",
    messagingSenderId: "664317567523",
    appId: "1:664317567523:web:2d265572e3a38f59204796",
    measurementId: "G-N1S9M0BDG3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Configura a persistência
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Se houver sucesso, a persistência está configurada
  })
  .catch((error) => {
    console.error('Erro ao configurar persistência: ', error);
  });

export { auth };
