import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

// Views
import LoginPage from '@/views/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import RulesPage from '@/views/RulesPage.vue';
import CampaignsPage from '@/views/CampaignsPage.vue';
import ConfigurationsPage from '@/views/ConfigurationsPage.vue';


import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase';

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: LoginPage },
  
  // Rota com layout padrão (DefaultLayout) para as páginas com menu
  {
    path: '/',
    component: DefaultLayout,
    children: [
      { path: '/dashboard', name: 'Dashboard', component: DashboardPage },
      { path: '/rules', name: 'Rules', component: RulesPage },
      { path: '/campaigns', name: 'Campaigns', component: CampaignsPage },
      { path: '/configurations', name: 'Configurations', component: ConfigurationsPage },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let isAuthenticated = false;

// Função que retorna uma Promise para aguardar a verificação de autenticação
const checkAuth = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    isAuthenticated = !!user;
    console.log('Usuário autenticado:', isAuthenticated);
    resolve();
  });
});

router.beforeEach(async (to, from, next) => {
  await checkAuth;

  if (!isAuthenticated && to.path !== '/login') {
    return next('/login');  // Redireciona para /login se não estiver autenticado
  }

  next();
});

export default router;
