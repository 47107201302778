<template>
  <div class="flex h-screen flex-col">
    <!-- Barra superior -->
    <header class="flex justify-between items-center bg-[#F9F9FA] p-4 shadow-md">
      <div class="text-[#444444] font-bold text-xl">Dataloyalty</div>
      <div class="flex items-center">
        <!-- Botão de usuário -->
        <button class="flex items-center bg-gray-200 p-2 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 text-[#444444]">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
        </button>
      </div>
    </header>

    <div class="flex flex-1">
      <!-- Sidebar -->
      <div class="w-64 white text-[#444444]">
        <ul>
          <li class="p-2">
            <router-link to="/dashboard"
              class="text-[#444444] flex items-center w-full p-2 hover:bg-[#F9F9FA] rounded-lg transition-all"
              active-class="bg-[#F9F9FA] font-bold" exact-active-class="bg-[#F9F9FA] font-bold">
              <ChartBarIcon class="w-5 h-5 mr-2" />
              Dashboard
            </router-link>

            <router-link to="/rules"
              class="text-[#444444] flex items-center w-full p-2 hover:bg-[#F9F9FA] rounded-lg transition-all"
              active-class="bg-[#F9F9FA] font-bold" exact-active-class="bg-[#F9F9FA] font-bold">
              <DocumentTextIcon class="w-5 h-5 mr-2" />
              Rules
            </router-link>

            <router-link to="/campaigns"
              class="text-[#444444] flex items-center w-full p-2 hover:bg-[#F9F9FA] rounded-lg transition-all"
              active-class="bg-[#F9F9FA] font-bold" exact-active-class="bg-[#F9F9FA] font-bold">
              <MegaphoneIcon class="w-5 h-5 mr-2" />
              Campaigns
            </router-link>

            <router-link to="/configurations"
              class="text-[#444444] flex items-center w-full p-2 hover:bg-[#F9F9FA] rounded-lg transition-all"
              active-class="bg-[#F9F9FA] font-bold" exact-active-class="bg-[#F9F9FA] font-bold">
              <MegaphoneIcon class="w-5 h-5 mr-2" />
              Configurations
            </router-link>

          </li>
        </ul>
      </div>

      <!-- Main content -->
      <div class="flex-1 p-6">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartBarIcon, DocumentTextIcon, MegaphoneIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'DefaultLayout',
  components: {
    ChartBarIcon,
    DocumentTextIcon,
    MegaphoneIcon,
  }
};
</script>

<style scoped>
/* Estilos gerais */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.bg-\[#F9F9FA\] {
  background-color: #F9F9FA;
}

/* Barra superior */
header {
  background-color: #F9F9FA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header .text-xl {
  font-size: 1.25rem;
}

header .flex {
  display: flex;
}

header .justify-between {
  justify-content: space-between;
}

/* Sidebar */
.w-64 {
  width: 16rem;
}

.p-2 {
  padding: 0.5rem;
}

.hover\:bg-\[#F9F9FA\]:hover {
  background-color: #F9F9FA;
}

.text-\[#9c459d\] {
  color: #9c459d;
}

.text-\[#444444\] {
  color: #444444;
}

.font-bold {
  font-weight: bold;
}

.w-full {
  width: 100%;
}

.rounded-lg {
  border-radius: 8px;
}

.transition-all {
  transition: all 0.3s ease;
}

.bg-gray-200 {
  background-color: #e2e8f0;
}

.bg-gray-200:hover {
  background-color: #cbd5e0;
}

button {
  cursor: pointer;
}
</style>
