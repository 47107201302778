<template>
  <div class="container mx-auto py-6 relative">
    <h1 class="text-3xl font-semibold mb-4">Configurations</h1>
    <form @submit.prevent="saveConfigurations" class="relative">
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>

      <div class="mb-4">
        <label for="applicationId" class="block text-sm font-medium text-gray-700 mb-1">
          Application ID
        </label>
        <input id="applicationId" v-model="form.applicationId" @input="filterOnlyNumbers" type="text"
          :disabled="isLoading" :class="{ 'bg-gray-200': isLoading }"
          class="border rounded-lg w-full px-4 py-2 focus:outline-none focus:ring-2"
          placeholder="Digite o Application ID" />
      </div>

      <div class="mb-4">
        <label for="key" class="block text-sm font-medium text-gray-700 mb-1">
          Key
        </label>
        <input id="key" v-model="form.key" type="text" :disabled="isLoading" :class="{ 'bg-gray-200': isLoading }"
          class="border rounded-lg w-full px-4 py-2 focus:outline-none focus:ring-2" placeholder="Digite a Key" />
      </div>

      <div class="mb-4 relative">
        <label for="secretKey" class="block text-sm font-medium text-gray-700 mb-1">
          Secret Key
        </label>
        <input id="secretKey" v-model="form.secretKey" :type="showSecretKey ? 'text' : 'password'" :disabled="isLoading"
          :class="{ 'bg-gray-200': isLoading }"
          class="border rounded-lg w-full px-4 py-2 focus:outline-none focus:ring-2"
          placeholder="Digite a Secret Key" />
        <button type="button" @click="toggleSecretKey" :disabled="isLoading"
          class="absolute right-3 top-9 text-gray-500 hover:text-gray-800">
          <span v-if="showSecretKey">👁️</span>
          <span v-else>👁️‍🗨️</span>
        </button>
      </div>

      <div>
        <button type="submit" :disabled="isLoading"
          class="bg-purple text-white px-6 py-2 rounded-lg shadow-md hover:bg-purple-dark focus:outline-none">
          Gravar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchConfigurations, updateConfigurations } from "@/services/configurationsService";

export default {
  name: "ConfigurationsPage",
  data() {
    return {
      form: {
        applicationId: "",
        key: "",
        secretKey: "",
      },
      showSecretKey: false,
      isLoading: false,
    };
  },

  async created() {
    try {
      this.isLoading = true;
      const config = await fetchConfigurations();
      if (config && config.configurationData) {
        this.form.applicationId = config.configurationData.appId || "";
        this.form.key = config.configurationData.credentials?.key || "";
        this.form.secretKey = config.configurationData.credentials?.secret || "";
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      this.$toast.error(`Erro ao carregar configurações - ${errorMessage}`);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    toggleSecretKey() {
      this.showSecretKey = !this.showSecretKey;
    },
    async saveConfigurations() {
      try {
        this.isLoading = true;
        // Certifique-se de que os campos "key" e "secretKey" estão preenchidos
        if (!this.form.key || !this.form.secretKey) {
          this.$toast.error("Key e Secret Key são obrigatórios.");
          return;
        }

        // Fazendo a chamada para atualizar as configurações
        await updateConfigurations({
          applicationId: this.form.applicationId,
          key: this.form.key,
          secretKey: this.form.secretKey,
        });
        this.showToast("Configurações salvas com sucesso!");
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        this.$toast.error(`Erro ao salvar configurações - ${errorMessage}`);
      } finally {
        this.isLoading = false;
      }
    },
    filterOnlyNumbers() {
      this.form.applicationId = this.form.applicationId.replace(/\D/g, "");
    },
    showToast(message) {
      this.$toast.success(message, 2000);
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  position: relative;
}

input {
  border-color: #9c459d;
}

input:focus {
  ring-color: #9c459d;
  outline-color: #9c459d;
}

.bg-purple {
  background-color: #9c459d;
}

.hover\:bg-purple-dark:hover {
  background-color: #7c337a;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #9c459d;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

input.bg-gray-200 {
  background-color: #e2e8f0;
}

.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.toast.success {
  background-color: #4caf50;
}

.toast.error {
  background-color: #f44336;
}

.toast-enter-active,
.toast-leave-active {
  transition: opacity 0.5s;
}

.toast-enter,
.toast-leave-to {
  opacity: 0;
}
</style>
