import axiosClient from "@/utils/axiosClient";

export const fetchConfigurations = async () => {
  const response = await axiosClient.get("/configurations");
  return response.data;
};

export const updateConfigurations = async (config) => {
  await axiosClient.put("/configurations", config);
  return true;
};
