<template>
  <div class="container mx-auto">
    <h1 class="text-3xl font-semibold mb-4">Bem-vindo à Campanhas</h1>
    <!-- Outros componentes ou conteúdo da página -->
  </div>
</template>

<script>
export default {
  name: 'CampaignsPage',
};
</script>

<style scoped>
/* Adicione estilos específicos para a página, se necessário */
</style>
