import { toast as vueToast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const DEFAULT_AUTO_CLOSE = 6000;

const ToastPlugin = {
    install(app) {
        app.config.globalProperties.$toast = {
            success(message, autoClose = DEFAULT_AUTO_CLOSE) {
                vueToast.success(message, { autoClose, position: 'top-right' });
            },
            error(message, autoClose = DEFAULT_AUTO_CLOSE) {
                vueToast.error(message, { autoClose, position: 'top-right' });
            },
            warning(message, autoClose = DEFAULT_AUTO_CLOSE) {
                vueToast.warning(message, { autoClose, position: 'top-right' });
            },
            info(message, autoClose = DEFAULT_AUTO_CLOSE) {
                vueToast.info(message, { autoClose, position: 'top-right' });
            },
        };
    },
};

export default ToastPlugin;
