import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './router/index.css';
import ToastPlugin from './plugins/ToastPlugin';


createApp(App)
  .use(router)
  .use(ToastPlugin)
  .mount('#app');
