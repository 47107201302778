<template>
  <div class="login-container">
    <form @submit.prevent="login">
      <h1>Login</h1>
      <input v-model="email" type="email" placeholder="Email" />
      <input v-model="password" type="password" placeholder="Senha" />
      <button type="submit">Entrar</button>
    </form>
  </div>
</template>

<script>
import { auth } from "@/firebase"; // Importa o Firebase Auth
import { signInWithEmailAndPassword } from "firebase/auth"; // Função de login

export default {
  name: "LoginPage",
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push('/dashboard');
      } catch (error) {
        console.error(error);
        alert('Falha no login. Verifique suas credenciais.');
      }
    },
  },
};
</script>




<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

form {
  background: #fff;
  width: 400px; /* Largura do formulário aumentada */
  padding: 30px; /* Espaçamento interno aumentado */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px; /* Tamanho do título ajustado */
}

input {
  display: block;
  width: calc(100% - 20px); /* Espaçamento interno nos campos */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Garante que o padding seja considerado no tamanho */
}

input:focus {
  outline: none;
  border-color: #9c459d; /* Adiciona destaque ao foco */
  box-shadow: 0 0 4px #9c459d;
}

button {
  background-color: #9c459d;
  color: white;
  border: none;
  padding: 12px 20px; /* Botão ligeiramente maior */
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #7c367d;
}
</style>
